import './RoundReportsFilters.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { bem } from 'lib/bem';
import { UsersSelect } from 'app/users';
import { t_prefixed } from 'lib/i18n';
import { convertDateToLocal, normalizeStartDate } from 'lib/helpers/dates';
import { Paper } from '@mui/material';
import { FormBuilder } from 'lib/ui';
import { change } from 'redux-form';
import { RoundReportsAuthorSelect } from 'app/roundsReports/RoundReportsAuthorSelect';

const { block, element } = bem('RoundReportsFilters');
const filterLabel = t_prefixed('round_reports.filters');

function formatMultiSelectValue(value) {
  if (value) {
    if (Array.isArray(value)) {
      return value;
    }

    if (typeof value === 'string') {
      return value.split(',');
    }

    return [value];
  }
  return [];
}

function normalizeUserValue(values) {
  return values.map((i) => {
    if (typeof i !== 'object') return i;
    return i.id;
  });
}

const RoundReportsFilters = ({ value }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { created_from, created_to } = value;

    if (created_from && created_to && moment(created_from).isAfter(created_to)) {
      dispatch(change('RoundReportsFilters', 'created_to', null));
    }
  }, [dispatch, value]);
  return (
    <Paper
      sx={{
        padding: '16px',
        border: '1px solid rgba(231, 238, 255, 0.72)',
        boxShadow: '0px 1px 12px -2px rgba(231, 238, 255, 0.64)',
        borderRadius: '12px',
        marginBottom: '16px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '1rem',
      }}
      {...block()}
    >
      <div {...element('dates')}>
        <FormBuilder.Date
          title={filterLabel('date')}
          name="created_from"
          placeholder={filterLabel('date_from')}
          normalize={normalizeStartDate}
          maxDate={moment()}
        />

        <FormBuilder.Date
          name="created_to"
          placeholder={filterLabel('date_to')}
          normalize={normalizeStartDate}
          minDate={convertDateToLocal(value.created_from)}
          format={convertDateToLocal}
        />
      </div>
      <UsersSelect
        {...element('users')}
        multiple
        name="responsible_ids"
        title={filterLabel('user')}
        normalize={normalizeUserValue}
        format={formatMultiSelectValue}
      />
      <RoundReportsAuthorSelect {...element('authors')} />

      <FormBuilder.Number {...element('number')} name="display_ids" title={filterLabel('id')} />

      <FormBuilder.MoreLessEqual
        {...element('more-less')}
        name="issues_count"
        title={filterLabel('related_issues')}
      />
    </Paper>
  );
};

export default RoundReportsFilters;
