import './RoundReports.scss';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import RoundGroupModal from 'app/roundsReports/RoundGroupModal';
import RoundReportsFilters from 'app/roundsReports/RoundReportsFilters';
import queryForm from 'lib/queryForm';
import RoundReportTable from 'app/roundsReports/RoundReportTable';
import { IRoundReportItem } from 'app/roundsReports/types';
import roundReportsResource from 'app/roundsReports/roundReports.resource';
import { FormBuilder, Page } from 'lib/ui';
import { t } from 'lib/i18n';
import { openModal } from 'lib/ui/Modal';

interface Props {
  roundList: { data: Array<IRoundReportItem> };
  filters: any;
}

const { block, element } = bem('RoundReports');

const filterForm = 'roundReportsForm';

const FilterForm = queryForm({ form: filterForm })(
  memo((props) => {
    const { offset, total } = (props as any).data;

    let routerOffset = offset ? parseInt(offset, 10) : undefined;
    if (routerOffset && Number.isNaN(routerOffset)) {
      routerOffset = undefined;
    }

    return (
      <>
        {!(props as any).paginationOnly && <RoundReportsFilters value={(props as any).values} />}
        <div {...element('pagination')}>
          <FormBuilder.Pagination
            total={total}
            normalize={String}
            format={Number}
            routerOffset={routerOffset}
          />
        </div>
      </>
    );
  }),
);

const RoundReports = reconnect((state, props) => {
  const filters = getFormValues(filterForm)(state) || {};
  if (Array.isArray(filters.responsible_ids)) {
    filters.responsible_ids = filters.responsible_ids.join();
  }
  if ('display_ids' in filters && filters.display_ids === null) {
    delete filters.display_ids;
  }

  const { responsible_ids, display_ids, issues_count } = props.router?.query ?? {};

  if (
    filters.responsible_ids !== responsible_ids ||
    filters.display_ids !== display_ids ||
    filters.issues_count !== issues_count
  ) {
    delete filters.offset;
  }

  const payload = { ...filters };
  return {
    roundList: roundReportsResource.list(state, payload, { skipFetchState: true }),
    filters,
  };
})(({ roundList, filters }: Props) => {
  const dispatch = useDispatch();
  const { data } = roundList;

  const handleRowClick = useCallback(
    (item) => {
      dispatch(openModal('roundGroup', item));
    },
    [dispatch],
  );

  return (
    <Page {...block()} title={t('menu.rounds_reports')} fullWidth>
      <RoundGroupModal />
      <FilterForm data={roundList} />
      {roundList && roundList.data && <RoundReportTable items={data} onRowClick={handleRowClick} />}
      <FilterForm data={roundList} paginationOnly />
    </Page>
  );
});

export default RoundReports;
