import { keyBy, uniq } from 'lodash';
import queryString from 'query-string';
import { put } from 'redux-saga/effects';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';

import { api } from 'lib/api';
// eslint-disable-next-line import/no-cycle
import { createResource, treeScope } from 'lib/resource';
// eslint-disable-next-line import/no-cycle
import locationsResource from 'app/locations/locations.resource';
import inventoriesResource from 'app/inventories/inventories.resource';
import reportsResource from 'app/reports/reports.resource';
import companiesResource from 'app/companies/companies.resource';
import { clearFcmToken } from 'lib/helpers/firebase/firebase';
import { getLocalStorageItem } from 'lib/helpers/localStorage';
import { closeModal } from 'lib/ui/Modal';
import { handleAuthTokenRefresh } from 'shared/api/utils';

import { ENTRY_MODAL } from '../entry/EntryModal/config';
import { SAVE_USER_MODAL } from './config';

export function addWeakLinks(user, parentId = '') {
  if (user.weakLink) return user;
  const userId = user.id;
  user.id = `${parentId}_${user.id}`;
  user.children = (user.children || []).map((i) => addWeakLinks(i, user.id));

  if (user.weak_links) {
    user.children = [
      {
        full_name: 'weak links',
        id: `${user.id}_weakLinks`,
        userId,
        weakLinks: true,
        children: user.weak_links.map((x) => ({
          ...x,
          userId: x.id,
          children: [],
          weakLink: true,
        })),
      },
      ...user.children,
    ];
  }

  user.userId = userId;

  return user;
}

export const guestUser = {
  isGuest: true,
  roles: ['guest'],
};

const clearTokenCodes = [403];

export const isCrossDomainAuth = () => {
  const locationSearch = queryString.parse(window.location.search || '');
  if (locationSearch) {
    return locationSearch.signin;
  }
  return null;
};

function handleAuthToken(userData) {
  const { httpStatusCode } = userData;

  const storedAuthToken = getLocalStorageItem('authToken');

  if (clearTokenCodes.includes(httpStatusCode) && storedAuthToken) {
    localStorage.removeItem('authToken');
  }

  handleAuthTokenRefresh(userData);

  return userData;
}

function handleGuestUser(userData) {
  const { response } = userData;
  if (response && !response.anonymous) return userData;

  return {
    ...userData,
    error: null,
    response: guestUser,
  };
}

function addDefaultUserRole(userData) {
  const { response } = userData;
  if (!response || response.isGuest) return userData;
  const { roles = [] } = response;

  return {
    ...userData,
    response: {
      ...userData.response,
      roles: uniq(['user', ...roles]),
    },
  };
}

function fetchLocationsData(userData) {
  const { response } = userData;

  const locationIds = uniq([
    ...(response.owned_locations || []),
    ...(response.managed_locations || []),
  ]);

  if (locationIds.length === 0) return Promise.resolve({});

  return (locationsResource as any).list
    .request({
      ids: locationIds.join(','),
    })
    .then((locationsData) => {
      const locationsIndex = keyBy(locationsData, 'id');
      const { owned_locations, managed_locations } = response;
      const getDataByIds = (ids) => (ids ? ids.map((id) => locationsIndex[id]) : null);
      return {
        owned_locations_data: getDataByIds(owned_locations),
        managed_locations_data: getDataByIds(managed_locations),
      };
    });
}

function fetchReportsData(user) {
  if (user.isGuest) {
    return {
      reports: [],
    };
  }

  return (reportsResource as any).list.request().then((reports) => ({
    reports: reports || [],
  }));
}

function fetchInventoriesData(user) {
  if (user.isGuest) {
    return {
      isResponsibleForInventory: false,
    };
  }

  return (inventoriesResource as any).list
    .request({
      limit: 1,
    })
    .then((inventories) => ({
      isResponsibleForInventory: (inventories || []).length > 0,
    }));
}

function fetchExtraData(userData) {
  const { response } = userData;
  if (!response) return userData;

  return Promise.all([
    fetchLocationsData(userData),
    fetchReportsData(userData.response),
    fetchInventoriesData(userData.response),
  ]).then(([locationsData, reportsData, inventoriesData]) => ({
    ...userData,
    response: {
      ...userData.response,
      ...locationsData,
      ...reportsData,
      ...inventoriesData,
    },
  }));
}

function* closeOnSuccess() {
  yield put(closeModal(SAVE_USER_MODAL));
}

export default createResource('users', {
  scopes: {
    list({ payload }) {
      return api.get('/users/', { search: payload });
    },

    roles() {},

    one({ payload }) {
      return api.get('/users/', { search: payload });
    },

    tree: {
      request({ payload }) {
        return api.get('/users/employees').then((json) => ({
          ...json,
          response: {
            id: -1,
            children: json.response && json.response.map(addWeakLinks),
          },
        }));
      },

      ...treeScope({}),
    },

    current: {
      request() {
        return api
          .get('/users/me')
          .then(handleAuthToken)
          .then(handleGuestUser)
          .then(addDefaultUserRole)
          .then(fetchExtraData);
      },

      selector: (data) => data,
      normalizer: (data) => ({ data }),

      options: {
        forceFetchOnMount: false,
      },
    },

    subordinates() {
      return api.get('/users/subordinates/');
    },

    assistants({ payload }) {
      return api.get('/users/assistants/', { search: payload });
    },

    employeeById: {
      request: ({ payload }) => api.get(`/v3/teorema/employees/${payload}/`),
      type: 'item',
    },
  },

  // Загружает данные пользователя,
  // вместе с привязанными и управляемыми локациями локациями
  byId({ payload }) {
    return api.get(`/users/${payload}/`).then((userData) => {
      if (!userData.response) return userData;

      return fetchLocationsData(userData).then((locationsData) => ({
        ...userData,
        response: {
          ...userData.response,
          ...locationsData,
        },
      }));
    });
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        if (payload.company_id?.id === 'new') {
          return companiesResource.create
            .request({ name: payload.company_id.name })
            .then((resp) =>
              api.post('/users/create', { body: { ...payload, company_id: resp.response.id } }),
            );
        }
        return api.post('/users/create', { body: payload });
      },
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        const { uid, ...body } = payload;
        if (body.company_id?.id === 'new') {
          return companiesResource.create
            .request({ name: payload.company_id.name })
            .then((resp) =>
              api.post(`/users/${uid}/update`, { body: { ...body, company_id: resp.response.id } }),
            );
        }
        return api.post(`/users/${uid}/update`, { body });
      },
      onSuccess: closeOnSuccess,
    },

    updateProfile({ payload }) {
      const { id, ...body } = payload;
      return api.post('/users/update', { body });
    },

    updatePassword({ payload }) {
      return api.post('/users/update_password/', { body: payload });
    },

    createPassword({ payload }) {
      return api.post('/users/create_password/', { body: payload });
    },

    remove({ payload }) {
      const { id, replacementUser } = payload;
      return api.post(`/users/${id}/delete/`, {
        body: replacementUser ? { replacement_user_id: replacementUser.id } : {},
      });
    },

    addEmployee({ payload }) {
      const { id, parentUserId, isStrong } = payload;

      return api.put(`/employees/${id}/`, {
        body: {
          parent_id: parentUserId === -1 ? null : parentUserId,
          is_strong: isStrong,
        },
      });
    },

    removeEmployee({ payload }) {
      const { parentUserId, id, isStrong } = payload;

      return api.post(`/users/${parentUserId}/employees/delete/`, {
        body: {
          descendant_id: id,
          is_strong: isStrong,
        },
      });
    },

    login: {
      request: ({ payload }) => {
        const { mobile, password } = payload;
        return api
          .post('/users/login/', {
            body: { mobile, password },
          })
          .then(handleAuthTokenRefresh);
      },
      *onSuccess() {
        yield put(closeModal(ENTRY_MODAL));
      },
    },

    quickLogin({ payload }) {
      return api.post('/v3/auth/login', { body: payload }).then(handleAuthTokenRefresh);
    },

    logout() {
      return api.post('/users/logout/').then((response) => {
        localStorage.removeItem('authToken');
        Cookies.remove('refresh-token');
        clearFcmToken();
        return response;
      });
    },

    anonymous({ payload }) {
      return api.post('/v3/auth/anonymous_login/', { body: payload }).then(handleAuthTokenRefresh);
    },

    async pushConfirmationCode({ payload }) {
      return api.post('/v3/auth/send_confirmation_code', {
        body: payload,
      });
    },
  },
});
