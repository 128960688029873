import { useEffect, useState } from 'react';
import { TextField, MenuItem, InputBase, Box } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { usePrevious } from 'lib/hooks/usePrevious';

const values = [
  {
    value: '?',
    label: <SearchIcon />,
  },
  {
    value: '>',
    label: '>',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '=',
    label: '=',
  },
  {
    value: '<>',
    label: '≠',
  },
];
const MoreLessEqual = ({ input: { onChange } }) => {
  const [symbol, setSymbol] = useState('?');
  const [value, setValue] = useState('');
  const handleSymbolChange = (event) => {
    setSymbol(event.target.value);
  };
  const handleValueChange = (evt) => {
    setValue(evt.target.value);
  };
  const prevSymbol = usePrevious(symbol);
  const prevValue = usePrevious(value);
  useEffect(() => {
    if (symbol && symbol !== '?' && value) {
      onChange(`${symbol}${value}`);
    }
  }, [onChange, symbol, value]);
  useEffect(() => {
    if (prevSymbol !== '?' && symbol === '?') {
      setValue('');
      onChange('');
    }
  }, [onChange, prevSymbol, symbol]);
  useEffect(() => {
    if (value && value !== prevValue && symbol === '?') {
      setSymbol('=');
    }
  }, [prevValue, symbol, value]);
  return (
    <Box
      sx={{
        padding: '1px 0px',
        display: 'flex',
        alignItems: 'center',
        width: 125,
        border: 'solid 1px #ccc',
      }}
    >
      <TextField
        id="standard-select-currency"
        select
        value={symbol}
        onChange={handleSymbolChange}
        variant="outlined"
        SelectProps={{
          IconComponent: undefined,
        }}
        sx={{
          '& .MuiSelect-select': {
            fontSize: '18px',
            lineHeight: '18px',
            padding: '5px',
          },
          '& .MuiInputBase-input': {
            padding: '5px !important',
          },
        }}
      >
        {values.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          '& .MuiInputBase-input': {
            border: 'none',
            paddingLeft: '0 !important',
          },
        }}
        type="number"
        value={value}
        onChange={handleValueChange}
      />
    </Box>
  );
};
export default MoreLessEqual;
