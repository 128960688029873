import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { Paper, Popper, ClickAwayListener, Grow, InputAdornment, Typography } from '@mui/material';

import { bem } from 'lib/bem';
import Search from 'lib/ui/Search';
import { Icon, GlyphName, IconAsButton } from 'lib/ui/icon';
import { extractFlatTree, getAllChildren, Node } from 'shared/lib/tree-view-helpers';
import { AsyncTreeViewLabels, AsyncTreeView, TreeData, TreeNode } from 'shared/ui/async-tree-view';
import { TextButton } from 'shared/ui/text-button';
import BusyIndicator from 'lib/ui/BusyIndicator';

import './AsyncTreeViewSelect.scss';

const { block, element } = bem('AsyncTreeViewSelect');

interface Props {
  label?: ReactNode;
  open: boolean;
  toggleSelect: (value: boolean) => void;
  treeData: TreeData;
  expandedNodeIds: string[];
  changeExpandedNodes: (nodes: string[]) => void;
  selectedNodeIds: string[];
  changeSelectedNodeIds: (nodes: string[]) => void;
  onToggleNode: (node: TreeNode) => void;
  search?: string;
  onSearchChange?: (value: string) => void;
  searchable?: boolean;
  multiSelect?: boolean;
  startIcon?: GlyphName;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  loading?: boolean;
  className?: string;
  title?: string;
}

export const AsyncTreeViewSelect = ({
  label,
  open,
  toggleSelect,
  treeData,
  expandedNodeIds,
  onToggleNode,
  selectedNodeIds,
  changeSelectedNodeIds,
  search,
  onSearchChange,
  searchable,
  multiSelect,
  startIcon,
  required,
  error,
  placeholder,
  loading,
  className,
  title,
}: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(
    (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLDivElement)) return;

      toggleSelect(false);
    },
    [toggleSelect],
  );

  const handleChangeSearch = useCallback(
    (e) => {
      onSearchChange?.(e.target.value);
    },
    [onSearchChange],
  );

  const onRemove = useCallback(
    (nodeId: number, parentIds: string[] = []) => {
      const children = getAllChildren(treeData, nodeId);

      const newSelectedNodes = selectedNodeIds.filter(
        (item) => !parentIds.includes(item) && item !== String(nodeId) && !children.includes(item),
      );

      changeSelectedNodeIds(newSelectedNodes);
    },
    [treeData, selectedNodeIds, changeSelectedNodeIds],
  );

  const visibleLabels: Node[] = useMemo(() => {
    if (!treeData) return [];

    const flatTree = extractFlatTree(treeData);

    return flatTree
      ? (selectedNodeIds.map((nodeId) => flatTree.get(Number(nodeId))) as Node[]).filter(
          (node) =>
            node != null && !(node.parentId && selectedNodeIds.includes(String(node.parentId))),
        )
      : [];
  }, [selectedNodeIds, treeData]);

  return (
    <div {...block({}, className)}>
      <div className={element('header', { only_clear: !title }).className}>
        <div {...element('title')}>
          {title && (
            <>
              <Typography component="div" className="FormField__label">
                {title}
              </Typography>
              {required && <div {...element('requiredMark')}>{t('common.required_sign')}</div>}
            </>
          )}
        </div>
        {multiSelect && selectedNodeIds.length !== 0 && (
          <TextButton onClick={() => changeSelectedNodeIds([])} color="primary" isUnderline>
            {t('common.clear')}
          </TextButton>
        )}
      </div>

      <div
        {...element('select', { error })}
        aria-describedby="asyncTreeViewSelect"
        ref={anchorRef}
        onClick={() => toggleSelect(!open)}
      >
        {startIcon && <Icon glyph={startIcon} {...element('start-icon')} />}

        {multiSelect ? (
          <AsyncTreeViewLabels onRemove={onRemove} visibleLabels={visibleLabels} />
        ) : !label && visibleLabels.length === 0 ? (
          <div {...element('placeholder')}>{placeholder}</div>
        ) : (
          label ?? visibleLabels[0]?.fullName ?? visibleLabels[0].name
        )}

        {loading ? (
          <BusyIndicator size={20} sx={{ marginLeft: 'auto' }} />
        ) : (
          <Icon {...element('arrow', { expand: open })} glyph="squareArrow" />
        )}
      </div>
      <Popper
        sx={{
          zIndex: 10,
          width: anchorRef?.current?.clientWidth,
          marginTop: '8px !important',
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom',
            }}
          >
            <Paper {...element('paper')}>
              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
                <div id="asyncTreeViewSelect">
                  {searchable && (
                    <Search
                      iconGlyph={search ? 'close' : 'search'}
                      adornment={
                        search && (
                          <InputAdornment position="end">
                            <IconAsButton glyph="close" onClick={() => onSearchChange?.('')} />
                          </InputAdornment>
                        )
                      }
                      autoFocus
                      value={search}
                      onChange={handleChangeSearch}
                      invisibleFocus
                      startSearchIcon
                    />
                  )}
                  <AsyncTreeView
                    treeData={treeData}
                    expandedNodeIds={expandedNodeIds}
                    selectedNodeIds={selectedNodeIds}
                    changeSelectedNodes={changeSelectedNodeIds}
                    multiSelect={multiSelect}
                    onToggleNode={onToggleNode}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
