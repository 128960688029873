import { createResource } from 'lib/resource';
import { api } from 'lib/api';

const versionPrefix = 'v3';

export default createResource('roundReports', {
  scopes: {
    list({ payload }) {
      return api.get(`/${versionPrefix}/round_groups/`, { search: payload });
    },
    roundGroups: {
      request({ payload }) {
        return api.get(`/${versionPrefix}/round_groups/${payload}/`);
      },
      selector(data, index) {
        return data;
      },
      normalizer(response) {
        return {
          data: response,
        };
      },
    },
  },
});
